import partnerData from "../../services"

export default {
    data() {
        return {
            editCustomMargin: this.$route.query.margin,
            customiseData: {}
        }
    },
    methods: {
        removeCustomiseProduct() {
            this.showLoader(true);
            partnerData.deletePartnerProduct(this.$route.params.id, this.$route.params.mappingId).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message)
                this.$router.push({
                    name: 'custom-margins'
                  });
            });
        },
        updateProductMargin() {
            this.customiseData = {
                margin_percent: parseInt(this.editCustomMargin)
            }
            this.showLoader(true);
            partnerData.updatePartnerProductMargin(this.$route.params.id, this.$route.params.mappingId, this.customiseData).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message)
                this.$router.push({
                    name: 'custom-margins'
                  });
            });
            this.editCustomMargin = "";
            this.customiseData = {}
        }
    }
}