<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Partners</h1>
    <router-link :to="{name: 'partners-create'}" class="header-icon header-icon-2"
        v-if="hasPermission('partner_create')">
        <i class="fa fa-user-plus"></i>
    </router-link>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-3">
        <i class="fa fa-search"></i>
    </a>
    <div class="header-icon header-icon-4 select-filter" style="width: 50px !important;" v-if="hasSuperAdmin">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder=""
            :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ filter.search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div id="tab-group-1">
        <div class="tab-controls card-style mx-2 mb-4 tabs-small tabs-rounded letterSpacing"
            data-highlight="bg-primary-light-color">
            <a href="#" class="line-height-s p-1 btn-ripple" data-active data-bs-toggle="collapse"
                @click="tabchange('active')" data-bs-target="#tab-1ab">
                {{ statistics.active ? statistics.active : 0 }}
                <br>
                Active
            </a>
            <a href="#" class="line-height-s p-1 btn-ripple" data-bs-toggle="collapse" @click="tabchange('pending')"
                data-bs-target="#tab-2ab">
                {{ statistics.pending ? statistics.pending : 0 }}
                <br>
                Pending
            </a>
            <a href="#" class="line-height-s p-1 btn-ripple" data-bs-toggle="collapse" @click="tabchange('inactive')"
                data-bs-target="#tab-3ab">
                {{ statistics.inactive ? statistics.inactive : 0 }}
                <br>
                InActive
            </a>
            <a href="#" class="line-height-s p-1 btn-ripple" data-bs-toggle="collapse" @click="tabchange('invited')"
                data-bs-target="#tab-4ab">
                {{ statistics.invited ? statistics.invited : 0 }}
                <br>
                Invited
            </a>
        </div>


        <!-- ACTIVE PARTNER LIST START -->
        <div data-bs-parent="#tab-group-1" class="collapse show" id="tab-1ab">
            <div v-for="record in partners.active" class="card card-style mb-2 mx-2">
                <div class="content my-2 mx-3">
                    <router-link :to="{'name': 'partner-detail', params: {'id' : record.id}}">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column justify-content-between">
                                <p class="primary-light-text text-capitalize font-16 mb-0 font-600 letterSpacing w-100">
                                    <i class="fas fa-check-circle color-green-dark"></i>
                                    {{ record.business_name.slice(0,22).toLowerCase().trim() }}{{
                                    record.business_name.length > 22 ? '...' : ''}}
                                </p>
                                <p
                                    class="font-12 line-height-s text-capitalize mt-1 mb-1 font-500 letterSpacing text-secondary">
                                    <i class="far fa-user font-16 me-1 primary-alt-base-text"></i>
                                    {{ record.full_name.toLowerCase().trim() }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="">
                                    <p class="font-10 mb-0 text-end">{{ localDate(record.created_at) }}</p>
                                    <!-- <h2 class="font-12 mb-n2 text-end">{{ record.country_emoji }}</h2> -->
                                    <h2 class="font-12 mb-1 mt-1 text-end text-capitalize letterSpacing font-500"
                                        :class="[record.partner_type ? 'partnerTypeBagde': '']">
                                        {{ record.partner_type || '-'}}
                                    </h2>
                                </div>
                                <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!partners.active.length">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Partner Found
                </p>
            </div>
        </div>
        <!-- ACTIVE PARTNER LIST END -->


        <!-- PENDING PARTNER LIST START -->
        <div data-bs-parent="#tab-group-1" class="collapse" id="tab-2ab">
            <div v-for="record in partners.pending" class="card card-style mb-2 mx-2">
                <div class="content my-1 mx-3">
                    <router-link :to="{'name': 'partner-detail', params: {'id' : record.id}}">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column justify-content-between">
                                <p class="primary-light-text text-capitalize font-16 font-500 mb-0 letterSpacing">
                                    {{ record.business_name.slice(0,22).toLowerCase().trim() }}{{
                                    record.business_name.length > 22 ? '...' : ''}}
                                </p>
                                <p class="text-secondary text-capitalize font-12 font-500 mb-0 letterSpacing">
                                    <i class="far fa-user primary-alt-light-text me-1"></i>
                                    {{ record.full_name.toLowerCase().trim() }}
                                </p>
                                <div class="font-12 mb-0 text-start text-capitalize">
                                    <span class="letterSpacing primary-alt-base-text"
                                        :class="[ record.partner_type ? 'partnerTypeBagde' : 'font-500' ]">
                                        {{ record.partner_type || '-' }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="">
                                    <p class="font-10 mb-0 text-end">{{ localDate(record.created_at) }}</p>
                                    <h2 class="font-12 mb-0 mt-2 text-end font-500 primary-alt-base-text letterSpacing">
                                        {{ record.referral_code || 'ERRGGDOOW' }}
                                    </h2>
                                </div>
                                <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!partners.pending.length">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Partner Found
                </p>
            </div>
        </div>
        <!-- PENDING PARTNER LIST END -->


        <!-- INACTIVE PARTNER LIST START -->
        <div data-bs-parent="#tab-group-1" class="collapse" id="tab-3ab">
            <div v-for="record in partners.inactive" class="card card-style mb-2 mx-2">
                <div class="content my-2 mx-3">
                    <router-link :to="{'name': 'partner-detail', params: {'id' : record.id}}">
                        <p class="color-highlight font-15 mb-n1">{{ record.business_name }} ({{ record.full_name }})</p>
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h2 class="font-12 line-height-s mt-1 mb-1">{{ record.partner_type }}</h2>
                            </div>
                            <div class="ms-auto ps-3 align-items-end">
                                <p class="font-10 mb-n2 text-end">{{ localDate(record.created_at) }}</p>
                                <h2 class="font-12 mb-0 text-end">{{ record.referral_code }}</h2>
                            </div>
                            <div class="d-flex ps-2 align-items-center">
                                <i class="fa fa-chevron-right font-10 text-secondary"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!partners.inactive.length">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Partner Found
                </p>
            </div>
        </div>
        <!-- INACTIVE PARTNER LIST END -->


        <!-- INVITED PARTNER LIST START -->
        <div data-bs-parent="#tab-group-1" class="collapse" id="tab-4ab">
            <div v-for="record in partners.invited" class="card card-style mb-2 mx-2">
                <div class="content my-1 mx-3">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex flex-column justify-content-between">
                            <p class="primary-light-text text-capitalize font-16 font-500 mb-0 letterSpacing">
                                {{ record.first_name }} {{ record.last_name }}
                            </p>
                            <h2 class="font-12 line-height-s mt-1 mb-1 letterSpacing font-400 text-secondary">
                                <i class="far fa-envelope me-1 color-red-dark"></i>
                                {{ record.email }}
                            </h2>
                            <h2 class="font-12 line-height-s mb-1 letterSpacing font-400 text-secondary">
                                <i class="fas fa-phone me-1 primary-light-text"></i>
                                {{ record.phone }}
                            </h2>
                            <p v-if="record.resend_count" class="font-12 line-height-s mt-1 mb-1">
                                {{ record.resend_count }} time invitation sent
                            </p>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="">
                                <p class="font-10 mb-0 text-end letterSpacing">{{ localDate(record.created_at) }}</p>
                                <h2 class="font-12 mb-0 text-end font-500 letterSpacing primary-alt-base-text">{{
                                    record.user_id ? "Registered" : 'Not registered' }}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!partners.invited.length">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Partner Found
                </p>
            </div>
        </div>
        <!-- INVITED PARTNER LIST END -->


    </div>
</div>

<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search " v-model="filter.search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!filter.search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!filter.search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>

<app-footer />