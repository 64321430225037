import ticketService from "../../services";
export default {
    data() {
        return {
            hasSuperAdmin: false,
            loginUserId: this.$store.state.user.id,
            transaction: {
                transaction_id: null,
                date: null,
                ticket_id: this.$route.params.id,
                attachment: "",
            },
            ticket: {},
            comments: [],
            newComment: {
                comment: null,
                ticket_id: this.$route.params.id,
                attachment: "",
            },
            formValidations: {
                transactionId: v => !!v || "Transaction Id is required",
                created_at: v => !!v || "Transaction Date is required",
                attachment: v => !!v || "Attachment is required",
            },
            ticketStatus: {
                'assigned': 'color-blue-dark',
                'created': 'color-gray-dark',
                'closed': 'color-red-dark'
            }
        };
    },
    mounted() {
        this.getTicketDetails();
        if ('user_type' in this.$store.state.user && this.$store.state.user.user_type == "super_admin") {
            this.hasSuperAdmin = true;
        }
    },
    methods: {
        getTicketDetails() {
            this.showLoader(true);
            // this.initializeModal();
            ticketService.getTicketDetail(this.$route.params.id).then(response => {
                this.hideLoader();
                this.ticket = response.data.data;
                this.comments = response.data.data.comments;
            })
        },
        submitComment() {
            var ticketComment = this.newComment;
            this.showLoader(true);
            this.$popper.wait("Creating...");
            ticketService.createTicketComments(ticketComment).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
                this.getTicketDetails();
            })
        },
        uploadFile(e) {
            this.showLoader(true);
            var files = e.target.files || e.dataTransfer.files;
            this.hideLoader();
            console.log(files[0])
            this.$popper.info("File Selected Successfully To Upload It Please Add Comment")
            this.newComment.attachment = files[0];
        },
        submitManualPaymentForm() {
            this.showLoader(true);
            this.$popper.wait("Approving...");
            ticketService.createManualPayment(this.$route.params.id, this.transaction).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message)
            })
            this.$router.push({name: 'ticket-home'})
        },
        submitPayNowForm() {
            this.showLoader(true);
            this.$popper.wait("Approving...");
            ticketService.cretaeAutoPayment(this.$route.params.id, {'pay_now': true}).then(response => {
                this.hideLoader();
                if (response.status == 200) {
                    this.$popper.success(response.data.message)
                } else {
                    this.$popper.error("Error");
                }
                this.getTicketDetails();
            })
        },
        approvePaymentRequest() {
            this.showLoader(true);
            this.$popper.wait("Approving...");
            ticketService.cretaeAutoPayment(this.$route.params.id).then(response => {
                this.hideLoader();
                if (response.status == 200) {
                    this.$popper.success(response.data.message)
                } else {
                    this.$popper.error("Error");
                }
                this.getTicketDetails();
            })
        },
        kycUpdate() {
            this.showLoader(true);
            this.$popper.wait("Processing...");
            ticketService.updateKyc(this.$route.params.id).then(response => {
                this.hideLoader();
                if (response.status == 200) {
                    this.$popper.success(response.data.message)
                } else {
                    this.$popper.error("Error");
                }
                this.getTicketDetails();
            })
        },
        bankDetailUpdate(){
            this.showLoader(true);
            this.$popper.wait("Processing...");
            ticketService.updateBankDetail(this.$route.params.id).then(response => {
                this.hideLoader();
                if (response.status == 200) {
                    this.$popper.success(response.data.message)
                } else {
                    this.$popper.error("Error");
                }
                this.getTicketDetails();
            })
        },
        closeTicket() {
            this.updateTicket({'status': "close"});
        },
        retryTicket() {
            this.updateTicket({'status': "retry"});
        },
        updateTicket(data) {
            this.showLoader(true);
            this.$popper.wait("Processing...");
            ticketService.updateTicket(this.$route.params.id, data).then(response => {
                this.hideLoader();
                if (response.status == 200) {
                    this.$popper.success(response.data.message)
                } else {
                    this.$popper.error("Error");
                }
                this.getTicketDetails();
            })
        }
    }
}