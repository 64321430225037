import store from "../../../../store";
import ticketService from "../../services";
export default {
    data() {
        return {
            searchedResultShow: false,
            hasSuperAdmin: false,
            country_id: (this.$route.query.country ? this.$route.query.country : this.defaultFilterCountryId()),
            search: "",
            icons: {
                1: 'fas fa-coins font-13 primary-alt-light-text',    // payment request
                2: 'fas fa-id-badge font-13 primary-light-text',   // kyc update
                3: 'fas fa-exclamation font-13 primary-alt-base-text',   // issue 
                4: 'fas fa-university font-13 primary-light-text',   // Bank detail update 
            },
            tickets: [],
            meta:{}
        };
    },
    watch: {
        'country_id': function () {
            this.$router.replace({query: { country: this.country_id }});
            this.getData();
        },
    },
    mounted() {
        if ('user_type' in store.state.user && store.state.user.user_type == "super_admin") {
            this.hasSuperAdmin = true;
        }
        this.getData();
        this.getNextRecord();
    },
    methods: {
        getData() {
            this.showLoader(true);
            ticketService.getTickets({
                'country_id': this.country_id,
                "search": this.search
            }).then(response => {
                this.hideLoader();
                this.tickets = response.data.data;
                this.meta = response.data.meta;
            });
        },
        export() {
            this.showLoader(true);
            ticketService.export({
                "country_id": this.country_id,
                "search": this.search
            }).then((response) => {
                this.hideLoader();
                this.generatePDF(response.data, "ticket_report");
            });
        },
        getNextRecord() {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.meta.current_page != this.meta.last_page && !this.loading) {
                    this.showLoader(true);
                    this.loading = true;
                    ticketService.getTickets({
                        'country_id': this.country_id,
                        "search": this.search,
                        'page': this.meta.current_page+1
                    }).then(response => {
                        this.hideLoader();
                        this.tickets.push(...response.data.data);
                        this.meta = response.data.meta;
                        this.loading = false;
                    })
                }
            }
        },
        filterUpdate() {
            this.meta.current_page = 1;
            this.getData();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.meta.current_page = 1;
            this.search = "";
            this.getData();
            this.searchedResultShow = false;
        },
    }
}