<app-header menu="no">
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Custom Margin</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-3">
        <i class="fa fa-search"></i>
    </a>
    <router-link :to="{name: 'custom-margins-create'}" class="header-icon header-icon-2">
        <i class="fa fa-plus"></i>
    </router-link>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">

    <div v-if="allProducts.length > 0" class="d-flex justify-content-center">
        <h2 class="letterSpacing font-13 font-500 mb-0 card px-3 py-1 rounded-l shadow-l" v-if="!searchProduct && allProducts.length > 0">
            {{ allProducts.length }} {{ allProducts.length > 1 ? 'Items' : 'Item' }}
        </h2>
        <h2 class="letterSpacing font-13 font-500 mb-0 card px-3 py-1 rounded-l shadow-l" v-if="searchProduct">
            {{ searchProductCount }} {{ searchProductCount > 1 ? 'Items Found' : 'Item Found' }}
        </h2>
    </div>
    <div v-for="CustomiseMarginProduct in (searchProduct==''?CustomiseMarginProducts:filteredGroups)" :key="CustomiseMarginProduct.key" class="mt-2 mb-4" v-if="CustomiseMarginProducts.length > 0">
        <div v-for="(product,index) in CustomiseMarginProduct.value" :key="product.id">
            <router-link
                :to="{'name': 'custom-margins-edit', params: {'mappingId' : product.id}, query: { margin: product.margin_percent, productName: product.sku }}">
                <div class="card card-style py-2 rounded-s mb-1 shadow-l">
                    <div class="content mb-2 mt-2" id="tab-group-1">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h2
                                    class="primary-alt-base-text font-400 font-14 line-height-s mt-1 mb-1 letterSpacing">
                                    {{ product.crm_product && product.crm_product.Product_Name }}
                                </h2>
                            </div>
                            <div class="ms-auto ps-3 align-items-end">
                                <h2 class="font-13 mb-0 text-end letterSpacing text-success">
                                    {{ product.margin_percent }}%
                                </h2>
                            </div>
                            <div class="d-flex ps-2 align-items-center">
                                <i class="fa fa-chevron-right font-10 text-secondary"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
    <div class="mt-5 text-center" v-if="!(CustomiseMarginProducts.length > 0)">
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No Product Found
        </p>
    </div>
</div>

<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-14 font-500">Filter By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control letterSpacing" type="text" placeholder="Search Product" v-model="searchProduct">
            <em></em>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="searchClear" :disabled="!searchProduct"
            class="close-menu btn btn-s btn-ripple ms-1 primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>

<app-footer />