export default {
    data() {
        return {
            report: {
                fromDate: null,
                toDate: null,
                reportType: null,
                partner: null,
            }
        };
    },
    methods: {
        generateReport() {
            console.log(this.report.fromDate, 'To', this.report.toDate)
        },
    }
}