export default {
    data() {
        return {
            report: {
                adminSchedule: null,
                clusterAdminSchedule: null,
                partnerAdminSchedule: null,
                partnerSchedule: null,
                email: null,
                phone: null,
            }
        };
    },
    methods: {
        submitSchedule(e) {
            e.preventDefault();
            console.log(this.report.adminSchedule, this.report.phone)
        }
    }
}