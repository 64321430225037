import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(source) {
        return api.instance.get("/get-select-data", {
            params: source
        });
    }

    sendInvitation(data) {
        return api.instance.post("/partner-invitation", data);
    }

    updateInvitation(id, data) {
        return api.instance.put("/partner-invitation/" + id, data);
    }

    resendInvitation(id) {
        return api.instance.post("/partner-invitation-resend/" + id);
    }

    getInvitedPartners(data) {
        return api.instance.get("/partner-invitation", { params: data });
    }

    getPartners(data) {
        return api.instance.get("/partners", { params: data });
    }

    getPartnerData(id) {
        return api.instance.get("/partners/" + id);
    }

    // getPartnerOrders(id) {
    //     return api.instance.get("partner-orders?partner_id");
    // }

    impersonate(userId) {
        return api.instance.get("/partner-admin-impersonate/" + userId);
    }

    approveDisApproveAccount(id, action) {
        return api.instance.get("/partner/approve-disapprove-account/" + action + "/" + id);
    }

    updatePersonalDetail(id, data) {
        return api.instance.put("/partners/" + id, data);
    }

    updateBusinessDetail(id, data) {
        let formData = new FormData();
        for (const [ key, value ] of Object.entries(data)) {
            formData.append(key, Array.isArray(value) ? value[ 0 ] : value);
        }
        return api.instance.post("/partner-profile-detail-update/" + id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    enableDisableCommission(id) {
        return api.instance.post("partner/enable-disable-commission/" + id);
    }

    enableDisablePayment(id) {
        return api.instance.post("partner/enable-disable-payment/" + id);
    }

    enableDisableCustomCommission(id, data) {
        return api.instance.post("partner/update-custom-commission/" + id, data);
    }
    updateMargin(id, data) {
        return api.instance.post("partner/update-margin/" + id, data);
    }

    //CUSTOM MARGIN PART

    getPartnerCustomiseProducts(id) {
        return api.instance.get("/users/" + id + "/partner-product-mappings");
    }

    getPartnerProductsMapping(id) {
        return api.instance.get("/users/" + id + "/partner-product-mappings/products");
    }

    setPartnerProductMargin(id, data) {
        return api.instance.post("/users/" + id + "/partner-product-mappings", data);
    }

    updatePartnerProductMargin(id, mappingId, data) {
        return api.instance.put("/users/" + id + "/partner-product-mappings/" + mappingId, data);
    }

    deletePartnerProduct(id, mappingId) {
        return api.instance.delete("/users/" + id + "/partner-product-mappings/" + mappingId);
    }
};