<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">report config</h1>
    <button @click="submitSchedule" type="submit" form="form" class="header-icon header-icon-2">
        <i class="fas fa-check color-green-dark font-19"></i>
    </button>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <h2 class="text-capitalize text-center mb-3 mt-2" style="letter-spacing:0.5px;">report schedule</h2>
    <Form novalidate id="form" v-slot="{ errors }" autocomplete="off">
        <div class="card card-style">
            <div class="content mt-4 mb-4">
                <div class="input-style input-style-active has-borders ">
                    <form-control>
                        <label class="text-capitalize">admin</label>
                        <select id="admin" class="bg-white" v-model="report.adminSchedule">
                            <option value="weekly" selected>Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="both">Both(Weekly & Monthly)</option>
                        </select>
                        <span><i class="fa fa-chevron-down"></i></span>
                        <i class="fa fa-check disabled valid color-green-dark"></i>
                        <i class="fa fa-check disabled invalid color-red-dark"></i>
                        <em></em>
                    </form-control>
                </div>
                <div class="input-style input-style-active has-borders ">
                    <form-control>
                        <label class="text-capitalize">cluster admin</label>
                        <select id="clusterAdmin" class="bg-white" v-model="report.clusterAdminSchedule">
                            <option value="weekly" selected>Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="both">Both(Weekly & Monthly)</option>
                        </select>
                        <span><i class="fa fa-chevron-down"></i></span>
                        <i class="fa fa-check disabled valid color-green-dark"></i>
                        <i class="fa fa-check disabled invalid color-red-dark"></i>
                        <em></em>
                    </form-control>
                </div>
                <div class="input-style input-style-active has-borders">
                    <form-control>
                        <label class="text-capitalize">partner admin</label>
                        <select id="partnerAdmin" class="bg-white" v-model="report.partnerAdminSchedule">
                            <option value="weekly" selected>Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="both">Both(Weekly & Monthly)</option>
                        </select>
                        <span><i class="fa fa-chevron-down"></i></span>
                        <i class="fa fa-check disabled valid color-green-dark"></i>
                        <i class="fa fa-check disabled invalid color-red-dark"></i>
                        <em></em>
                    </form-control>
                </div>
                <div class="input-style input-style-active has-borders ">
                    <form-control>
                        <label class="text-capitalize">partner</label>
                        <select id="partner" class="bg-white" v-model="report.partnerSchedule">
                            <option value="weekly" selected>Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="both">Both(Weekly & Monthly)</option>
                        </select>
                        <span><i class="fa fa-chevron-down"></i></span>
                        <i class="fa fa-check disabled valid color-green-dark"></i>
                        <i class="fa fa-check disabled invalid color-red-dark"></i>
                        <em></em>
                    </form-control>
                </div>
            </div>
        </div>
    </Form>
</div>
<app-footer />