import partnerDataService from "../../services";

export default {
    data() {
        return {
            partner: {},
            selectData: {},
            citiesData: {},
            personalDetailValidations: {
                first_name: v => !!v || "First Name is required",
                last_name: v => !!v || "Last Name is required",
                email: v => v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required",
                country_id: v => !!v || "Country is required",
                phone: v => v ? (/^\d+$/.test(v) || 'Phone must be valid') : "Phone is required",
                partner_type_id: v => !!v || "Partner type selection is required"
            },
            businessDetailValidations: {
                business_name: v => !!v || "Name is required",
                address: v => !!v || "Address is required ",
                zipcode: v => v ? (/^(?=.{6,})/.test(v) || 'Zipcode must be 6 digits') : "Zipcode is required",
                city_id: v => !!v || "City is required",
                state_id: v => !!v || "State is required",
                country_id: v => !!v || "Country is required",
            },
            customCommissionRole: v => v ? (/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/.test(v) || 'Custom commission must be valid') : "Custom commission is required",
            marginPercentRole: v => v ? (/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/.test(v) || 'Margin percentage must be valid') : "Margin percentage is required",
            currentTab: 'account',
            types: {
                'input_file': 'file',
                'input_text': 'text',
                'input_number': 'number',
            },
            approveAction: "approve",
            allProducts: []
        }
    },
    created() {

        this.getPartnerData();
        this.getCustomiseProducts();
    },
    watch: {
        'partner.state_id'(selected) {
            this.partner.city = "";
            if (selected && this.selectData.cities != undefined) {
                this.citiesData = this.selectData.cities.filter((value) => {
                    return (value.state_id == selected)
                })
            } else {
                this.citiesData = this.selectData.cities;
            }
        },
        'partner.city_id'(selected) {
            if (selected) {
                for (var key in this.selectData.cities) {
                    if (this.selectData.cities[ key ].id == selected) {
                        this.partner.state = this.selectData.cities[ key ].state_id;
                    }
                }
            }
        }
    },
    methods: {
        getPartnerData() {
            var select = {
                'type': [ 'partnerTypes', 'partnerAll', 'states', 'cities' ],
                'country_id': ""
            };
            partnerDataService.getPartnerData(this.$route.params.id).then(response => {
                this.partner = response.data.data;
                // this.partner.role = this.partner.role.filter((item) => item !== "partner");
                select.country_id = this.partner.country_id;
                partnerDataService.getSelectData(select).then(response => {
                    this.selectData = response.data;
                    this.citiesData = this.selectData.cities;
                    Object.keys(this.partner.fields).forEach(item => {
                        var abc = this.registrationFieldValidationRule(this.partner.fields[ item ]);
                        this.businessDetailValidations = {
                            ...this.businessDetailValidations,
                            ...abc
                        };
                    });
                    window.init_template();
                });
            });
        },
        submitPersonalDetailForm() {
            this.showLoader(true);
            this.$popper.wait("Updating...");
            partnerDataService.updatePersonalDetail(this.partner.id, this.partner).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
                this.getPartnerData();
            });
        },
        submitBusinessDetailForm() {
            this.showLoader(true);
            this.$popper.wait("Updating...");
            partnerDataService.updateBusinessDetail(this.partner.id, this.partner).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
            });
        },
        disApprove() {
            this.approveAction = "disapprove"
        },
        approve() {
            this.approveAction = "approve"
        },
        approveAccount() {
            this.showLoader(true);
            partnerDataService.approveDisApproveAccount(this.$route.params.id, 'approve').then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message);
                if (this.partner.registration_approved == "pending") {
                    this.partner.commission_enable = "enabled";
                    this.partner.payment_enable = "enabled";
                }
                this.partner.registration_approved = "approved";
            });
        },
        disApproveAccount() {
            this.showLoader(true);
            partnerDataService.approveDisApproveAccount(this.$route.params.id, 'disapprove').then(response => {
                this.hideLoader();
                this.partner.registration_approved = "disapproved";
                if (this.partner.registration_approved == "pending") {
                    this.partner.commission_enable = "enabled";
                    this.partner.payment_enable = "enabled";
                }
                this.$popper.success(response.data.message);
            });
        },
        accountStatusChange() {
            if (this.partner.registration_approved) {
                this.approveAccount();
            } else {
                this.disApproveAccount();
            }
        },
        commissionChange() {
            this.showLoader(true);
            partnerDataService.enableDisableCommission(this.$route.params.id).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message);
            });
        },
        paymentChange() {
            this.showLoader(true);
            partnerDataService.enableDisablePayment(this.$route.params.id).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message);
            });
        },
        customCommissionChange() {
            if (!this.partner.custom_commission_enable) {
                this.showLoader(true);
                this.hideLoader();
                partnerDataService.enableDisableCustomCommission(this.$route.params.id, {
                    'custom_commission_enable': this.partner.custom_commission_enable
                }).then(response => {
                    this.$popper.success(response.data.message);
                });
            }
        },
        updateCustomCommission() {
            this.showLoader(true);
            partnerDataService.enableDisableCustomCommission(this.$route.params.id, {
                'custom_commission_enable': this.partner.custom_commission_enable,
                'custom_commission': this.partner.custom_commission
            }).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message);
            });
        },
        updateMargin() {
            this.showLoader(true);
            partnerDataService.updateMargin(this.$route.params.id, {
                'margin_percent': this.partner.margin_percent
            }).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message);
            });
        },
        getCustomiseProducts() {
            partnerDataService.getPartnerCustomiseProducts(this.$route.params.id).then(response => {
                this.allProducts = response.data.map(p => {
                    return p;
                });
                this.hideLoader();
            });
        }
    }
}