<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Tickets</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-3">
        <i class="fa fa-search"></i>
    </a>
    <div class="header-icon header-icon-4 select-filter" style="width: 50px !important;" v-if="hasSuperAdmin">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder=""
            :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
    <router-link :to="{name: 'ticket-create'}" class="header-icon header-icon-2" v-if="hasPermission('ticket_create')">
        <i class="fa fa-plus"></i>
    </router-link>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div class="d-flex justify-content-end" v-if="(tickets.length>0)">
        <button class="btn btn-xs mb-2 me-3 letterSpacing" @click="export"  style="background-color: rgb(174, 191, 218);">
            <i class="fas fa-download"></i>
            Export
        </button>
    </div>
    <div v-for="record,index in tickets" class="" v-if="tickets && tickets.length > 0">
        <div class="card card-style mb-2 rounded-s">
            <div class="content mt-0 mb-0">
                <router-link :to="{name:'ticket-detail',params: {'id' : record.id}}">
                    <div class="d-flex">
                        <div class="d-flex flex-column justify-content-around">
                            <p class="font-15 font-300 mb-2 text-capitalize letterSpacing text-secondary">
                                {{record.title.length
                                >=20 ? record.title.substring(0,17)+"..." : record.title}}
                            </p>
                            <p class="font-12 font-500 line-height-s mb-2 letterSpacing">
                                <span class="primary-light-text rounded-l px-2" style="padding-block: 2px; border: 1px solid var(--primary-light-color);"
                                    v-if="record.ticket_type_name.toLowerCase() == 'payment request'">
                                    {{record.ticket_type_name}}
                                </span>
                                <span class="primary-alt-base-text rounded-l px-2" style="padding-block: 2px; border: 1px solid var(--primary-alt-base-color);"
                                    v-if="record.ticket_type_name.toLowerCase() == 'issue'">
                                    {{record.ticket_type_name}}
                                </span>
                                <span class="primary-alt-light-text rounded-l px-2" style="padding-block: 2px; border: 1px solid var(--primary-alt-light-color);"
                                    v-if="record.ticket_type_name.toLowerCase() == 'kyc update'">
                                    {{record.ticket_type_name}}
                                </span>
                            </p>
                        </div>
                        <div class="ms-auto ps-3">
                            <p class="mb-0 text-end font-500 text-secondary">{{ record.unique_id }}</p>
                            <p class="font-10 mb-0 mt-0 text-end">{{ localDate(record.created_at) }}</p>
                            <p class="primary-alt-base-text font-600 letterSpacing mb-0 fst-italic"
                                v-if="hasSuperAdmin">
                                {{record.user_name}}
                            </p>
                        </div>
                        <div class="d-flex ps-2 align-items-center">
                            <i class="fa fa-chevron-right text-secondary"></i>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
    <div class="mt-5 text-center" v-if="!(tickets.length>0)">
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No Ticket Found
        </p>
    </div>
</div>
<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search " v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>

<app-footer />