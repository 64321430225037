import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(data) {
        return api.instance.get("/get-select-data", {
            params: data
        });
    }
    getPartners(data) {
        return api.instance.get("/partners", {
            params: data
        });
    }
    getTickets(query) {
        return api.instance.get("/tickets", {
            params: query
        })
    }
    createTicket(data) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, Array.isArray(value) ? value[0] : value);
        }
        return api.instance.post("/tickets", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    getTicketCustomFields(query) {
        return api.instance.get("/tickets/create", {
            params: query
        });
    }
    getTicketDetail(id) {
        return api.instance.get("/tickets/" + id)
    }
    createTicketComments(data) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, Array.isArray(value) ? value[0] : value);
        }
        return api.instance.post("/ticket-comment", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    cretaeAutoPayment(id, data) {
        return api.instance.post("/ticket-payment-request/" + id, data)
    }
    createManualPayment(id, data) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, Array.isArray(value) ? value[0] : value);
        }
        return api.instance.post("/ticket-manual-payment-request/" + id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    updateTicket(id, data) {
        return api.instance.put("/tickets/" + id, data)
    }
    updateKyc(id) {
        return api.instance.post("/ticket-kyc-update/" + id)
    }

    updateBankDetail(id) {
        return api.instance.post("/ticket-bank-detail-update/" + id)
    }

    export(data) {
        data['export'] = true;
        return api.instance.get('/tickets',{responseType: 'blob',params: data});
    }
};