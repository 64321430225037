import partnerData from "../../services"

export default {
    data() {
        return {
            CustomiseMarginProducts: [],
            allProducts: [],
            searchProduct: '',
            searchProductCount: 0,
            filteredGroups: [],
        }
    },
    mounted() {
        this.showLoader(true);
        this.getCustomiseProducts();
    },
    watch: {
        'searchProduct': function () {
            this.getSearchedProducts();
        }
    },
    methods: {
        groupBy(list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [ item ]);
                } else {
                    collection.push(item);
                }
            });
            return [ ...map ].map(([ name, value ]) => ({ name, value }));
        },

        getCustomiseProducts() {
            partnerData.getPartnerCustomiseProducts(this.$route.params.id).then(response => {
                this.allProducts = response.data.map(p => {
                    return p;
                });
                this.hideLoader();
                this.CustomiseMarginProducts = this.groupBy(this.allProducts, function (p) {
                    let parts = p.sku.split('-');
                    parts.pop();
                    return parts.join('-');
                });
            });
        },
        getSearchedProducts() {
            if (this.searchProduct == '') {
                this.filteredGroups = this.CustomiseMarginProducts;
                this.searchProductCount = 0;
                return;
            }
            this.searchProductCount = 0;
            this.filteredGroups = this.CustomiseMarginProducts.map((group) => {
                var matchedProducts = group.value.filter((product) => {

                    return product.crm_product.Product_Name.toLowerCase().indexOf(this.searchProduct.toLowerCase()) != -1;
                });
                this.searchProductCount += matchedProducts.length;
                return { "name": group.name, "value": matchedProducts };
            })
        },
        searchClear() {
            this.searchProduct = '';
            this.filteredGroups = this.groups;
            this.searchProductCount = 0;
        }

    }
}