import partnerData from "../../services";
import orderService from "../../../order/services";

export default {
    data() {
        return {
            partner: {},
            fieldText: {},
            approveAction: 'disapprove',
            partnerActivePage: 'partner_profile',
            orders: [],
            searchedResultShow:false,
            search:'',
            meta: {
                current_page: 1,
            },
        }
    },
    mounted() {
        this.getPartnerData();
        this.getOrders();
    },
    methods: {
        impersonate() {
            this.showLoader(true);
            partnerData.impersonate(this.$route.params.id).then(response => {
                const url = window.location.origin + '/user/impersonate?token=' + response.data.token;
                this.copyToClipboard(url).then(() => {
                    alert('Link copied. Please open incognito window & paste it.');
                });

                this.hideLoader();
            });
        },

        getPartnerData() {
            this.showLoader(true);
            partnerData.getPartnerData(this.$route.params.id).then(response => {
                this.hideLoader();
                this.partner = response.data.data;
            });
        },

        getOrders() {
            this.showLoader(true);
            orderService.getOrders(
                {   'partner_id':this.$route.params.id,
                    'search': this.search
                }).then(response => {
                    this.hideLoader();
                    this.orders = response.data.data;
                    this.meta.current_page = response.current_page;
                });
        },
        getNextRecord() {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.meta.current_page != this.meta.last_page && !this.loading) {
                    this.showLoader(true);
                    this.loading = true;
                    orderService.getOrders({
                        "search": this.search,
                        'page': this.meta.current_page + 1
                    }).then(response => {
                        this.hideLoader();
                        this.orders.push(...response.data.data);
                        this.meta = response.data.meta;
                        this.loading = false;
                    })
                }
            }
        },
        filterUpdate() {
            this.meta.current_page = 1;
            this.getOrders();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.meta.current_page = 1;
            this.search = "";
            this.getOrders();
            this.searchedResultShow = false;
        },
    }
}