<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Edit Partner</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">
            <div class="col-12 text-center">
                <p class="color-highlight mb-0"></p>
            </div>
            <div v-if="currentTab == 'account'">
                <Form novalidate @submit="submitPersonalDetailForm" :validation-schema="personalDetailValidations"
                    v-slot="{ errors }" autocomplete="off">
                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.first_name}">
                        <i class="fa fa-user"></i>
                        <Field as="input" type="text" name="first_name" v-model="partner.first_name"
                            class="form-control" id="first_name" placeholder="First Name"></Field>
                        <label for="first_name" class="color-primary-dark font-13 font-600">First Name</label>
                        <em>(required)</em>
                        <template v-slot:error>{{errors.first_name}}</template>
                    </form-control>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.last_name}">
                        <i class="fa fa-user"></i>
                        <Field as="input" type="text" name="last_name" v-model="partner.last_name" class="form-control"
                            id="last_name" placeholder="Last Name"></Field>
                        <label for="last_name" class="color-primary-dark font-13 font-600">Last Name</label>
                        <em>(required)</em>
                        <template v-slot:error>{{errors.last_name}}</template>
                    </form-control>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.email}">
                        <i class="fa fa-at"></i>
                        <Field as="input" type="email" name="email" v-model="partner.email" class="form-control"
                            id="email" placeholder="Email"></Field>
                        <label for="email" class="color-primary-dark font-13 font-600">Email</label>
                        <em>(required)</em>
                        <template v-slot:errors>{{errors.email}}</template>
                    </form-control>

                    <div class="input-group raw">
                        <div class="col-4">
                            <form-control class="validate-field input-style-active"
                                :class="{'is-invalid' : errors.country_id}">
                                <app-select name="country_id" placeholder="Country" disabled="true"
                                    :options="$store.state.selectData.phoneCodes" value-prop="id"
                                    v-model="partner.country_id">
                                    <template v-slot:default="slotProps">
                                        {{ slotProps.item.emoji }} &nbsp;(+{{ slotProps.item.phone_code }})
                                    </template>
                                    <template v-slot:display="slotProps">
                                        <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                                        {{ slotProps.item.name }} <span class="">(+{{ slotProps.item.phone_code
                                            }})</span>
                                    </template>
                                </app-select>
                                <template v-slot:errors>{{errors.country_id}}</template>
                            </form-control>
                        </div>
                        <div class="col ms-2">
                            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.phone}">
                                <i class="fa fa-mobile"></i>
                                <Field as="input" type="number" name="phone" v-model="partner.phone"
                                    class="form-control validate-number" id="phone" placeholder="Phone No"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    maxlength="15"></Field>
                                <label for="phone" class="color-primary-dark font-13 font-600">Phone No</label>
                                <em>(required)</em>
                                <template v-slot:errors>{{errors.phone}}</template>
                            </form-control>
                        </div>
                    </div>

                    <form-control class="validate-field input-style-active"
                        :class="{'is-invalid' : errors.partner_type_id}">
                        <app-select name="partner_type_id" placeholder="Partner Type" :options="selectData.partnerTypes"
                            value-prop="id" v-model="partner.partner_type_id">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.text }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.partner_type_id}}</template>
                    </form-control>

                    <form-control class="validate-field input-style-active"
                        :class="{'is-invalid' : errors.parent_partner_id}">
                        <app-select name="parent_partner_id" placeholder="Parent Partner"
                            :options="selectData.partnerAll" value-prop="id" v-model="partner.parent_partner_id">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.full_name }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.parent_partner_id}}</template>
                    </form-control>


                    <form-control class="validate-field input-style-active">
                        <app-select name="user_roles" placeholder="User Roles" multiple="true"
                            :options="partner.all_roles" value-prop="id" v-model="partner.role">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.name }}
                            </template>
                        </app-select>
                    </form-control>

                    <div class="d-flex justify-content-center mt-4">
                        <button class="btn btn-l btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s">
                            Update
                        </button>
                    </div>
                </Form>
            </div>
            <div v-show="currentTab == 'business'">
                <Form novalidate @submit="submitBusinessDetailForm" :validation-schema="businessDetailValidations"
                    v-slot="{ errors }" autocomplete="off">
                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.business_name}">
                        <i class="fa fa-briefcase"></i>
                        <Field as="input" type="text" name="business_name" v-model="partner.business_name"
                            class="form-control" id="businessName" placeholder="Business Name"></Field>
                        <label for="businessName" class="color-primary-dark font-13 font-600">Business Name</label>
                        <template v-slot:error>
                            {{errors.business_name}}
                        </template>
                    </form-control>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.address}">
                        <i class="fa fa-briefcase"></i>
                        <Field as="input" type="text" name="address" v-model="partner.address" class="form-control"
                            id="address" placeholder="Address"></Field>
                        <label for="address" class="color-primary-dark font-13 font-600">Address</label>
                        <template v-slot:error>
                            {{errors.address}}
                        </template>
                    </form-control>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.zipcode}">
                        <i class="fa fa-map-marker-alt"></i>
                        <Field as="input" type="number" name="zipcode" v-model="partner.zipcode" class="form-control"
                            id="zipcode" placeholder="Zipcode"></Field>
                        <label for="zipcode" class="color-primary-dark font-13 font-600">Zipcode</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.zipcode}}
                        </template>
                    </form-control>

                    <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.country_id}">
                        <app-select name="country_id" disabled="true" placeholder="Country"
                            :options="$store.state.selectData.phoneCodes" value-prop="id" v-model="partner.country_id">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.emoji }} &nbsp; {{ slotProps.item.name }}
                            </template>
                            <template v-slot:display="slotProps">
                                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span> {{ slotProps.item.name }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.country_id}}</template>
                    </form-control>

                    <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.state_id}">
                        <app-select name="state_id" placeholder="State" :options="selectData.states" value-prop="id"
                            v-model="partner.state_id">
                            <template v-slot:default="slotProps">
                                <span class="font-600 me-2">{{ slotProps.item.text }}</span>
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.state_id}}</template>
                    </form-control>

                    <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.city_id}">
                        <app-select name="city_id" placeholder="City" :options="citiesData" value-prop="id"
                            v-model="partner.city_id">
                            <template v-slot:default="slotProps">
                                <span class="font-600 me-2">{{ slotProps.item.text }}</span>
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.city_id}}</template>
                    </form-control>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.business_website}">
                        <i class="fa fa-link"></i>
                        <Field as="input" type="text" name="business_website" v-model="partner.website"
                            class="form-control" id="businessWebsite" placeholder="Business Website"></Field>
                        <label for="businessWebsite" class="color-primary-dark font-13 font-600">Business
                            Website</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.business_website}}
                        </template>
                    </form-control>
                    <div v-for="(fields,key,index) in partner.fields">
                        <div class="col-12 mt-5">
                            <h1 class="font-14 letterSpacing primary-alt-base-text">{{ getLabel(key) }}</h1>
                        </div>
                        <div v-for='(field, index) in fields' :key='field.id'>
                            <label v-if="field.type == 'input_file'" class="color-primary-dark font-13 font-600">{{
                                getLabel(field.name) }}</label>
                            <form-control class="validate-field" :class="{'is-invalid' : errors[field.name]}">
                                <Field as="input" :name="field.name" :type="types[field.type]"
                                    v-model="partner[field.name]" :rules="businessDetailValidations[field.name]"
                                    class="form-control" :id="field.name+'_field'" :placeholder="getLabel(field.name)">
                                </Field>
                                <label :for="field.name+'_field'" v-if="field.type != 'input_file'"
                                    class="color-primary-dark font-13 font-600">
                                    {{ getLabel(field.name) }}
                                </label>
                                <em>(required)</em>
                                <template v-slot:error>
                                    {{errors[field.name]}}
                                </template>
                            </form-control>
                        </div>
                        <!-- <registration-group :group="key" :fieldData="fields" @back="" @submit="assignStep(key,$event)"></registration-group> -->
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <button class="btn btn-l btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s">
                            Update
                        </button>
                    </div>
                </Form>
            </div>
            <div v-if="currentTab == 'setting'">
                <div class="d-flex justify-content-between align-items-center mb-2 border-bottom pb-2">
                    <p class="color-theme font-13 m-0 font-500 letterSpacing">Approve/Disapprove Account</p>
                    <div v-if="partner.registration_approved == 'pending'">
                        <a href="#" class="icon icon-xs rounded-xl primary-light-bg text-white ms-2" @click="approve()"
                            data-menu="accountApproveConfirm">
                            <i class="fa fa-user-check"></i>
                        </a>
                        <a href="#" class="icon icon-xs rounded-xl primary-alt-base-bg text-white ms-2"
                            @click="disApprove()" data-menu="accountApproveConfirm">
                            <i class="fa fa-user-alt-slash"></i>
                        </a>
                    </div>
                    <div v-if="partner.registration_approved != 'pending'" data-trigger-switch="account-toggle"
                        aria-expanded="false" aria-controls="collapseExample3">
                        <div class="ms-auto me-4 pe-2">
                            <div class="custom-control ios-switch ">
                                <input type="checkbox" class="ios-input" id="account-toggle"
                                    :checked="partner.registration_approved == 'approved'"
                                    @input="partner.registration_approved = $event.target.checked"
                                    v-on:change="accountStatusChange">
                                <label class="custom-control-label" for="account-toggle"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-2 border-bottom pb-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="color-theme font-13 m-0 font-500 letterSpacing">Enable Custom Commission</p>
                        <div class="" data-trigger-switch="toggle-id-1" data-bs-toggle="collapse"
                            href="#collapseExample3" role="button" aria-expanded="false"
                            aria-controls="collapseExample3">
                            <div class="ms-auto me-4 pe-2">
                                <div class="custom-control ios-switch ">
                                    <input type="checkbox" class="ios-input" id="toggle-id-1"
                                        :checked="partner.custom_commission_enable == 'enabled'"
                                        @input="partner.custom_commission_enable = $event.target.checked"
                                        v-on:change="customCommissionChange">
                                    <label class="custom-control-label" for="toggle-id-1"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" :class="{'show': partner.custom_commission_enable == 'enabled'}"
                        id="collapseExample3">
                        <Form novalidate @submit="updateCustomCommission" class="d-flex  pt-2" v-slot="{ errors }"
                            autocomplete="off">
                            <div>
                                <form-control class="validate-field has-icon"
                                    :class="{'is-invalid' : errors.business_website}">
                                    <i class="fa fa-percent"></i>
                                    <Field as="input" type="number" name="business_website"
                                        v-model="partner.custom_commission" :rules="customCommissionRole"
                                        class="form-control" id="businessWebsite" placeholder="Percentage"></Field>
                                    <label for="businessWebsite"
                                        class="color-primary-dark font-13 font-600">Percentage</label>
                                    <em>(required)</em>
                                    <template v-slot:error>
                                        {{errors.business_website}}
                                    </template>
                                </form-control>
                            </div>
                            <div class="ms-2">
                                <button
                                    class="btn btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s p-3">
                                    Update
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
                <div class="mb-2 border-bottom pb-2">
                    <Form novalidate @submit="updateMargin" class="d-flex flex-column justify-content-between pt-2"
                        v-slot="{ errors }" autocomplete="off">
                        <div class="pb-2">
                            <label for="margin_percent" class="color-primary-dark font-13 font-500 letterSpacing">Margin
                                (%)</label>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <form-control class="validate-field has-icon"
                                    :class="{'is-invalid' : errors.margin_percent}">
                                    <i class="fa fa-percent"></i>
                                    <Field as="input" type="number" name="margin_percent"
                                        v-model="partner.margin_percent" :rules="marginPercentRole"
                                        class="form-control letterSpacing font-600" id="margin_percent"
                                        placeholder="Margin"></Field>
                                    <template v-slot:error>
                                        {{errors.margin_percent}}
                                    </template>
                                </form-control>
                            </div>
                            <div class="">
                                <button
                                    class="btn btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s p-3">
                                    Set Margin
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-2 border-bottom pb-2">
                    <p class="color-theme font-13 m-0 font-500 letterSpacing">Approve/Disapprove Commission</p>
                    <div data-trigger-switch="commission-toggle" aria-expanded="false" aria-controls="collapseExample3">
                        <div class="ms-auto me-4 pe-2">
                            <div class="custom-control ios-switch ">
                                <input type="checkbox" class="ios-input" id="commission-toggle"
                                    v-on:change="commissionChange" :checked="partner.commission_enable == 'enabled'"
                                    @input="partner.commission_enable = $event.target.checked">
                                <label class="custom-control-label" for="commission-toggle"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <p class="color-theme font-13 m-0 font-500 letterSpacing">Approve/Disapprove Payment</p>
                    <div data-trigger-switch="payment-toggle">
                        <div class="ms-auto me-4 pe-2">
                            <div class="custom-control ios-switch">
                                <input type="checkbox" class="ios-input" id="payment-toggle" v-on:change="paymentChange"
                                    :checked="partner.payment_enable == 'enabled'"
                                    @input="partner.payment_enable = $event.target.checked">
                                <label class="custom-control-label" for="payment-toggle"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center" v-if="currentTab == 'setting'">
        <router-link :to="{name: 'custom-margins'}"
            class="btn btn-ripple font-500 font-13 primary-alt-base-bg letterSpacing rounded-s py-2">
            Set Custom Margin ({{ allProducts.length }} {{ allProducts.length > 1 ? 'Items' : 'Item' }})
        </router-link>
    </div>
</div>
<div id="footer-bar" class="footer-bar-2">
    <a href="#" :class="{'active-nav': currentTab == 'account'}" @click="currentTab = 'account'"><i
            class="fa fa-user btn-ripple"></i><span class="letterSpacing">Account Details</span></a>
    <a href="#" :class="{'active-nav': currentTab == 'business'}" @click="currentTab = 'business'"><i
            class="fa fa-building btn-ripple"></i><span class="letterSpacing">Business Details</span></a>
    <a href="#" :class="{'active-nav': currentTab == 'setting'}" @click="currentTab = 'setting'"><i
            class="fa fa-cog btn-ripple"></i><span class="letterSpacing">Settings</span></a>
</div>

<!-- <div id="accountApproveConfirm" class="menu menu-box-bottom rounded-m" data-menu-height="230" data-menu-effect="menu-over">
    <div class="menu-title">
        <i class="fa fa-question-circle scale-box float-start me-3 ms-3 fa-3x mt-1 color-blue-dark"></i>
        <p class="color-highlight">We need to know,</p>
        <h1 class="font-20">Are you sure to {{ approveAction == "approve" ? 'Approve' : "Disapprove" }}?</h1>
        <a href="#" class="close-menu"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="content mt-0">
        <p class="pe-3">
            This is confirmation to {{ approveAction == "approve" ? 'approve' : "disapprove" }} the account.
        </p>

        <div class="row mb-0 justify-content-center">
            <div class="col-6">
                
            </div>
        </div>

    </div>
</div> -->
<div id="accountApproveConfirm" class="menu menu-box-bottom rounded-m" data-menu-height="250"
    data-menu-effect="menu-over">
    <div class="menu-title">
        <a href="#" class="close-menu"><i class="fa fa-times-circle"></i></a>
        <p class="color-red-dark letterSpacing">We need to know,</p>
        <h1 class="font-20 letterSpacing font-500">Are you sure to {{ approveAction == "approve" ? 'Approve' :
            "Disapprove" }}?</h1>
    </div>
    <div class="content mt-0">
        <p class="pe-3 font-500 font-13 letterSpacing">
            This is confirmation to {{ approveAction == "approve" ? 'approve' : "disapprove" }} the account.
        </p>
        <div class="row mb-0 justify-content-center">
            <div class="col-8">
                <!-- <a href="#" class="btn close-menu btn-full btn-m bg-green-dark font-600 rounded-s">Yes, proceed!</a> -->
                <a href="#" class="btn close-menu btn-full btn-m primary-light-bg letterSpacing font-600 rounded-s"
                    v-if="approveAction == 'approve'" @click="approveAccount">Yes, Approve!</a>
                <a href="#" class="btn close-menu btn-full btn-m primary-alt-base-bg letterSpacing font-600 rounded-s"
                    v-if="approveAction == 'disapprove'" @click="disApproveAccount">Yes, Disapprove!</a>
            </div>
        </div>
    </div>
</div>

<app-footer />