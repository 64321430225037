import ticketService from "../../services";
import store from "../../../../store";

export default {
    data() {
        return {
            hasSuperAdmin: false,
            issueTicketTypes: [],
            requestTicketTypes: [],
            customFields: {},
            userCountry: [],
            partners: [],
            ticketCategories: [{
                name: "Issue"
            }, {
                name: "Request"
            }],
            types: {
                'input_file': 'file',
                'input_text': 'text',
                'input_number': 'number',
            },
            ticket: {
                ticketCategory: null,
                title: null,
                description: null,
                ticket_type_id: null,
                user_id: null,
            },
            formValidations:{},
            codeValidation: {
                fullName: v => !!v || "Partner is required",
                ticketCategory: v => !!v || "Ticket Catogary is required",
                issueType: v => !!v || "Ticket Type is required",
                requestType: v => !!v || "Sequence is required",
                title: v => !!v || "Title is required",
                description: v => !!v || "Description is required",
            },
        };
    },
    watch: {
        'ticket.user_id': function () {
            if (this.hasSuperAdmin) {
                this.getCustomField();
            }
        },
        'ticket.ticket_type_id': function (val) {
            const result = this.customFields.filter(word => word.ticket_type_id == val);
            var customValidation = this.registrationFieldValidationRule(result);
            this.formValidations = {
                ...this.codeValidation,
                ...customValidation
            };
        },
    },

    mounted() {
        this.formValidations = this.codeValidation;
        if ('user_type' in store.state.user && store.state.user.user_type == "super_admin") {
            this.hasSuperAdmin = true;
        }
        ticketService.getSelectData({
                'type': ['ticketTypes', 'partnerAll']
            })
            .then((response) => {
                this.partners = response.data.partnerAll;
                this.issueTicketTypes = response.data.ticketTypes.filter(data => {
                    return data.group == "Issue";
                });
                this.requestTicketTypes = response.data.ticketTypes.filter(data => {
                    return data.group == "Request";
                });
            })

        if (!this.hasSuperAdmin) {
            ticketService.getTicketCustomFields().then(response => {
                this.customFields = response.data.attributes;
            });
        }

    },

    methods: {
        getCustomField() {
            if (this.ticket.user_id) {
                ticketService.getTicketCustomFields({
                    'user_id': this.ticket.user_id
                }).then(response => {
                    this.customFields = response.data.attributes;
                    this.userCountry = response.data.country;
                });
            }
        },
        submitForm() {
            this.showLoader(true);
            this.$popper.wait("Creating...");
            ticketService.createTicket(this.ticket).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
                this.$router.push({
                    name: 'ticket-home'
                })
            });
        },
    }
}