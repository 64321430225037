<app-header menu="no">
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">
        {{ $route.query.productName }}
        {{ $route.query.productName && $route.query.productName.length > 10 ? `${$route.query.productName.slice(0,10)}...` : $route.query.productName }}
    </h1>
    <!-- <a href="#" @click="removeCustomiseProduct" class="header-icon header-icon-2 font-15">
        <i class="fa fa-trash text-danger"></i>
    </a> -->
    <a href="#" class="header-icon header-icon-2 font-15" @click="approve()" data-menu="itemDeleteConfirmation">
        <i class="fa fa-trash text-danger"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">


    <!-- UPDATE PRODUCT CUSTOM MARGIN START -->
    <div class="mt-2">
        <div class="card-style shadow-none rounded-s mb-1">
            <form-control class="has-icon">
                <i class="fas fa-percentage"></i>
                <Field as="input" type="number" name="edit_margin" v-model="editCustomMargin"
                    class="form-control letterSpacing font-500 rounded-l" id="edit_margin"
                    placeholder="Edit Custom Margin">
                </Field>
                <em></em>
            </form-control>
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-ripple w-50 font-500 font-13 primary-light-bg letterSpacing rounded-s py-2"
                @click="updateProductMargin">
                Update Custom Margin
            </button>
        </div>
    </div>
    <!-- UPDATE PRODUCT CUSTOM MARGIN END -->
</div>


<div id="itemDeleteConfirmation" class="menu menu-box-bottom rounded-m" data-menu-height="250"
    data-menu-effect="menu-over">
    <div class="menu-title">
        <a href="#" class="close-menu"><i class="fa fa-times-circle"></i></a>
        <h1 class="font-15 font-400 letterSpacing">Are you sure to delete the item?</h1>
    </div>
    <div class="content mt-0">
        <p class="pe-3 font-500 font-13 letterSpacing">
            This is confirmation to delete &nbsp;
            <span class="primary-alt-base-text font-700">
                {{ $route.query.productName }} 
            </span>
            &nbsp;
            item.
        </p>
        <div class="row mb-0 justify-content-center">
            <div class="d-flex justify-content-around">
                <a href="#" class="btn close-menu btn-full col-5 btn-m primary-alt-base-bg letterSpacing font-600 rounded-s">No</a>
                <a href="#" class="btn close-menu btn-full col-5 btn-m primary-light-bg letterSpacing font-600 rounded-s"
                    @click="removeCustomiseProduct">Yes, Delete</a>
            </div>
        </div>
    </div>
</div>

<app-footer />