<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Create Ticket</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">

            <!-- form part -->
            <Form novalidate id="form" @submit="submitForm" :validation-schema="formValidations" v-slot="{ errors }"
                autocomplete="off">
                <!-- Partner selection for super admin -->
                <div v-if="hasSuperAdmin">
                    <form-control class="validate-field input-style input-style-active has-borders "
                        :class="{'is-invalid' : errors.fullName}">
                        <app-select name="fullName" placeholder="Partner" :options="partners" value-prop="id"
                            v-model="ticket.user_id">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.full_name }}

                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.fullName}}</template>
                    </form-control>
                    <!-- <form-control class="validate-field input-style input-style-active has-borders " :class="{'is-invalid' : errors.type}">
                        <app-select name="type" placeholder="Input Type" :options="inputTypes" value-prop="value" v-model="customNewAttribute.type">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.name }}
                            </template>
                            <template v-slot:display="slotProps">
                                {{ slotProps.item.name }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.type}}</template>
                    </form-control> -->
                </div>

                <!-- ticket catogary selection part -->
                <div class="mb-3 input-style input-style-active">
                    <form-control class="validate-field input-style input-style-active has-borders "
                        :class="{'is-invalid' : errors.ticketCategory}">
                        <app-select name="ticketCategory" placeholder="Ticket Category" :options="ticketCategories"
                            value-prop="name" v-model="ticket.ticketCategory">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.name }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.ticketCategory}}</template>
                    </form-control>
                </div>

                <!-- ticket type selection part-->
                <div class="mb-3" v-show="ticket.ticketCategory == 'Issue' ">
                    <form-control class="validate-field input-style input-style-active has-borders "
                        :class="{'is-invalid' : errors.issueType}">
                        <app-select name="issueType" placeholder="Ticket Type" :options="issueTicketTypes"
                            value-prop="id" v-model="ticket.ticket_type_id">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.text }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.issueType}}</template>
                    </form-control>
                </div>
                <div class="mb-3" v-show="ticket.ticketCategory == 'Request'">
                    <form-control class="validate-field input-style input-style-active has-borders "
                        :class="{'is-invalid' : errors.requestType}">
                        <app-select name="requestType" placeholder="Ticket Type" :options="requestTicketTypes"
                            value-prop="id" v-model="ticket.ticket_type_id">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.text }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.requestType}}</template>
                    </form-control>
                </div>

                <!-- dynamic fields -->
                <div v-for='(field, index) in customFields' :key='field.id'>
                    <div v-if="ticket.ticket_type_id==field.ticket_type_id" class="">
                        <label v-if="field.type == 'input_file'"
                            class="color-primary-dark font-13 font-600 text-capitalize">{{ getLabel(field.name)
                            }}</label>
                        <form-control class="validate-field input-style-active"
                            :class="{'is-invalid' : errors[field.name], 'has-icon': (ticket.ticket_type_id == getTicketTypeId('payment_request') && field.name == 'amount')}">
                            <i class="mt-n3 pt-1 font-16 currencyIcon ms-n1"
                                v-if="ticket.ticket_type_id == getTicketTypeId('payment_request') && field.name == 'amount'">
                                {{ userCountry.currency_symbol ? userCountry.currency_symbol : "" }}
                            </i>
                            <Field as="input" :name="field.name" :type="types[field.type]" v-model="ticket[field.name]"
                                class="form-control" :id="field.name+'_field'" :placeholder="getLabel(field.name)">
                            </Field>
                            <label :for="field.name+'_field'" v-if="field.type != 'input_file'"
                                class="color-primary-dark font-13 font-600 text-capitalize">
                                {{ getLabel(field.name) }}
                            </label>
                            <em>(required)</em>
                            <template v-slot:error>
                                {{errors[field.name]}}
                            </template>
                        </form-control>
                    </div>
                </div>

                <!-- Title part -->
                <form-control class="validate-field has-icon input-style-active" :class="{'is-invalid' : errors.title}">
                    <i class="fas fa-heading"></i>
                    <Field as="input" type="text" name="title" v-model="ticket.title" class="form-control" id="title"
                        placeholder="Title"></Field>
                    <label for="title" class="color-primary-dark font-13 font-600">Title </label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.title}}
                    </template>
                </form-control>

                <!-- Description part -->
                <form-control class="validate-field has-icon input-style-active"
                    :class="{'is-invalid' : errors.description}">
                    <i class="fas fa-align-justify"></i>
                    <Field as="textarea" type="text" name="description" v-model="ticket.description"
                        class="form-control" id="description" placeholder="Description"></Field>
                    <label for="description" class="color-primary-dark font-13 font-600">Description</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.description}}
                    </template>
                </form-control>
            </Form>
        </div>
    </div>
</div>
<app-footer v-if="$store.state.user.user_type == 'super_admin'">
<!-- <app-footer> -->
    <div class="d-grid "
        :class="[$store.state.user.user_type == 'super_admin' ? 'superAdminCreateTicketBtnAction' : 'generalCreateTicketBtnAction']"
        style="width: 100%;">
        <button type="submit" form="form" class="btn btn-ripple font-400 py-3 font-15 primary-light-bg letterSpacing rounded-0 text-uppercase ticketButtonText">
            Create Ticket
        </button>
    </div>
</app-footer>