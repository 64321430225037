<app-header menu="no">
    <div v-if="!setMarginPageActive">
        <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
            <i class="fa fa-arrow-left"></i>
        </a>
        <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">
            Create Custom Margins
        </h1>
        <a href="#" data-menu="searchMenu" class="header-icon header-icon-2">
            <i class="fa fa-search"></i>
        </a>
    </div>
    <div v-if="setMarginPageActive">
        <button @click="setMarginPageActive = false" class="header-icon header-icon-1">
            <i class="fa fa-arrow-left"></i>
        </button>
        <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">
            {{ product.Product_Name && product.Product_Name.length > 10 ? `${product.Product_Name.slice(0,35)}...` :
            product.Product_Name }}
        </h1>
    </div>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">

    <!-- PRODUCTS LISTING START -->
    <div v-if="!setMarginPageActive" class="d-flex justify-content-center">
        <h2 class="letterSpacing font-13 font-500 mb-0 card px-3 py-1 rounded-l shadow-l"
            v-if="!searchProduct && allProducts.length > 0">
            {{ allProducts.length }} {{ allProducts.length > 1 ? 'Items' : 'Item' }}
        </h2>
        <h2 class="letterSpacing font-13 font-500 mb-0 card px-3 py-1 rounded-l shadow-l" v-if="searchProduct">
            {{ searchProductCount }} {{ searchProductCount > 1 ? 'Items Found' : 'Item Found' }}
        </h2>
    </div>
    <div v-for="group in (searchProduct==''?groups:filteredGroups)" :key="group.key" class="mt-2 mb-3"
        v-if="!setMarginPageActive && groups.length > 0">
        <div v-for="(product,index) in group.value" :key="product.id">
            <button class="w-100" @click="setMargin(product.id)">
                <div class="card card-style mb-1 rounded-s shadow-l">
                    <div class="content my-3" id="tab-group-1">
                        <div class="d-flex justify-content-between">
                            <div class="align-self-center">
                                <h5 class="letterSpacing font-13 font-500 mb-0">{{product.Product_Name}}</h5>
                            </div>
                            <div class="d-flex ps-2 align-items-center">
                                <i class="fa fa-chevron-right font-10"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </button>
        </div>
    </div>
    <div class="mt-5 text-center" v-if="!(allProducts.length > 0)">
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No Product Found
        </p>
    </div>
    <!-- PRODUCTS LISTING END -->


    <!-- SET PRODUCT CUSTOM MARGIN START -->
    <div v-if="setMarginPageActive" class="mt-2">
        <div class="card-style shadow-none rounded-s mb-1">
            <form-control class="has-icon">
                <i class="fas fa-percentage"></i>
                <Field as="input" type="number" name="margin" v-model="customMargin"
                    class="form-control letterSpacing font-500 rounded-l" id="margin" placeholder="Custom Margin">
                </Field>
                <em></em>
            </form-control>
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-ripple w-50 font-500 font-13 primary-light-bg letterSpacing rounded-s py-2"
                @click="addProductMargin">
                Add Custom Margin
            </button>
        </div>
    </div>
    <!-- SET PRODUCT CUSTOM MARGIN END -->

</div>

<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-14 font-500">Filter By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control letterSpacing" type="text" placeholder="Search Product" v-model="searchProduct">
            <em></em>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="searchClear" :disabled="!searchProduct"
            class="close-menu btn btn-s btn-ripple ms-1 primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>

<app-footer />