<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">reports</h1>
    <div class="header-icon header-icon-2">

    </div>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">
            <h2 class="text-capitalize text-center font-400" style="letter-spacing:0.5px;">reports</h2>
            <div class="divider bg-blue-dark divider-margins"></div>
            <Form novalidate :validation-schema="formValidations" v-slot="{ errors }" autocomplete="off">
                <div class="row justify-content-evenly">

                    <div class="input-style input-style-active has-borders col-md-5 mt-2">
                        <form-control>
                            <label class="text-capitalize color-primary-dark font-13 font-600">partner</label>
                            <select class=" bg-white" id="validationCustom04" v-model="report.partner">
                                <option disabled value="">partner</option>
                                <option>...</option>
                            </select>
                            <span><i class="fa fa-chevron-down"></i></span>
                            <i class="fa fa-check disabled valid color-green-dark"></i>
                            <i class="fa fa-check disabled invalid color-red-dark"></i>
                            <em></em>
                        </form-control>
                    </div>
                    <div class="input-style input-style-active has-borders col-md-5 mt-2">
                        <form-control>
                            <label class="text-capitalize color-primary-dark font-13 font-600">partner</label>
                            <select id=" partner" class="bg-white" v-model="report.reportType">
                                <option selected disabled>Choose...</option>
                                <option value="all">All</option>
                                <option value="commission">Commission</option>
                                <option value="payment">Payment</option>
                                <option value="customer">Customer</option>
                            </select>
                            <span><i class="fa fa-chevron-down"></i></span>
                            <i class="fa fa-check disabled valid color-green-dark"></i>
                            <i class="fa fa-check disabled invalid color-red-dark"></i>
                            <em></em>
                        </form-control>
                    </div>

                </div>
                <div class="row justify-content-evenly">
                    <div class="col-md-5 mt-2">
                        <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.fromDate}">
                            <Field as="input" type="date" name="fromDate" v-model="report.fromDate" class="form-control" id="fromDate"></Field>
                            <label for="fromDate" class="color-primary-dark font-13 font-600">From</label>
                            <template v-slot:error>{{errors.fromDate}}</template>
                        </form-control>
                    </div>
                    <div class="col-md-5 mt-2">
                        <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.toDate}">
                            <Field as="input" type="date" name="toDate" v-model="report.toDate" class="form-control" id="toDate"></Field>
                            <label for="toDate" class="color-primary-dark font-13 font-600">To</label>
                            <template v-slot:error>{{errors.toDate}}</template>
                        </form-control>
                    </div>
                </div>
                <div class="d-flex justify-content-evenly">
                    <button class="btn btn-s btn-ripple btn-full font-600 font-15 gradient-green rounded-s" @click="generateReport">Generate </button>
                </div>
            </Form>
        </div>
    </div>
    <div class="card card-style">
        <div class="content">
            <button class="btn btn-s btn-ripple btn-full font-600 font-13 gradient-highlight rounded-s"><i class="fas fa-download"></i> &nbsp; Export</button>
            <button class="btn btn-s btn-ripple btn-full font-600 font-13 gradient-highlight rounded-s float-end">Send Mail</button>
        </div>
    </div>
</div>
<app-footer />