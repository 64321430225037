import partnerData from "../../services";
import router from "@/router"

export default {
    data() {
        return {
            partner: { },
            formValidations: {
                first_name: v => !!v || "First Name is required",
                last_name: v => !!v || "Last Name is required",
                email: v => v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required",
                country: v => !!v || "Country is required",
                phone: v => v ?(/^\d+$/.test(v) || 'Phone must be valid') : "Phone is required",
            },
            isInvited: false,
            invited:{}
        }
    },
    mounted() {
        //
    },
    methods: {
        submitForm() {
            this.showLoader(true);
            this.$popper.wait("Sending...");
            if(!this.isInvited) {
                partnerData.sendInvitation(this.partner).then(response =>{
                    this.$popper.info(response.data.message);
                    this.partner = {};
                    this.hideLoader();
                    router.push({'name': 'partners-home'});
                }).catch(error => {
                    if (error.response.data.status == "InvitationExist") {
                        this.invited = error.response.data.data
                        this.$refs.confirmationMenu.click();
                    }
                });
            } else {
                partnerData.updateInvitation(this.invited.id, this.partner).then(response =>{
                    this.$popper.info(response.data.message);
                    this.hideLoader();
                    router.push({'name': 'partners-home'});
                });
            }
        },
        editInvitation() {
            this.isInvited = true
            this.partner = this.invited
        },
        resendInvitation() {
            this.showLoader(true);
            partnerData.resendInvitation(this.invited.id).then(response =>{
                this.$popper.info(response.data.message);
                this.hideLoader();
                router.push({'name': 'partners-home'});
            });
        }
    }
}