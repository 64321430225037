<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{ticket.unique_id}}</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>

    <div class="header-icon header-icon-2"
        v-if="hasPermission('ticket_payment_request_update') && ticket.ticket_type_id == getTicketTypeId('payment_request') && ticket.status != 'closed'">
        <button type="button" class="btn text-dark dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static"
            aria-expanded="false">
        </button>
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end"
            style="padding: 0 !important;margin: 0 !important;">
            <div v-if="ticket.ticket_payment_request_status == 'pending'">
                <li class="border-bottom"><button class="dropdown-item" data-menu="manualPaymentModal">Record
                        Payout</button></li>
                <li class="border-bottom"><button class="dropdown-item" @click="approvePaymentRequest">Approve</button>
                </li>
                <li class="border-bottom"><button class="dropdown-item" data-menu="PayNowPaymentModal">Pay Now</button>
                </li>
            </div>
            <li v-if="ticket.ticket_payment_request_status == 'failed'"><button class="dropdown-item"
                    @click="retryTicket">Retry Ticket</button></li>
            <li v-if="ticket.status != 'closed'"><button class="dropdown-item" @click="closeTicket">Close
                    Ticket</button></li>
        </ul>
    </div>
    <div class="header-icon header-icon-2"
        v-if="hasPermission('ticket_kyc_update') && ticket.ticket_type_id == getTicketTypeId('kyc_update') && ticket.status != 'closed'">
        <button type="button" class="btn text-dark dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static"
            aria-expanded="false">
        </button>
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end"
            style="padding: 0 !important;margin: 0 !important;">
            <li><button class="dropdown-item" @click="kycUpdate" type="button">Approve KYC</button></li>
            <li><button class="dropdown-item" @click="closeTicket">Close Ticket</button></li>
        </ul>
    </div>
    <div class="header-icon header-icon-2"
        v-if="hasPermission('ticket_bank_detail_update') && ticket.ticket_type_id == getTicketTypeId('bank_detail_update') && ticket.status != 'closed'">
        <button type="button" class="btn text-dark dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static"
            aria-expanded="false">
        </button>
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end"
            style="padding: 0 !important;margin: 0 !important;">
            <li><button class="dropdown-item" @click="bankDetailUpdate" type="button">Approve Bank Details</button></li>
            <li><button class="dropdown-item" @click="closeTicket">Close Ticket</button></li>
        </ul>
    </div>
    <div class="header-icon header-icon-2"
        v-if="hasPermission('ticket_issue_update') && ticket.ticket_type_id == getTicketTypeId('issue') && ticket.status != 'closed'">
        <button type="button" class="btn text-dark dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static"
            aria-expanded="false">
        </button>
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end"
            style="padding: 0 !important;margin: 0 !important;">
            <li><button class="dropdown-item" @click="closeTicket">Close Ticket</button></li>
        </ul>
    </div>
    <div :class="ticket.status == 'closed' ? 'header-icon header-icon-3' : 'header-icon header-icon-4'">
        <p class="font-15 font-600 mb-0">
            <span class="text-capitalize" :class="ticketStatus[ticket.status]">{{ ticket.status }}</span>
        </p>
    </div>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">



    <div class="content mx-4 d-flex justify-content-between align-items-center mt-2 mb-3">
        <p class="font-15 font-800 text-capitalize letterSpacing mb-0" v-if="hasSuperAdmin">
            <i class="fas fa-user-circle font-20"></i>
            {{ticket.user_name}}
        </p>
        <span class="letterSpacing primary-alt-light-bg px-3 py-2 mb-1 rounded-l text-white font-500">
            {{ticket.ticket_type_name}}
        </span>
    </div>



    <div class="card card-style" v-if="ticket">
        <div class="content">
            <h3 class="font-500 color-theme text-capitalize mb-2 letterSpacing font-17">
                {{ticket.title}}
            </h3>

            <div class="divider mb-3"></div>

            <p class="font-14 font-400 color-grey mb-2 letterSpacing">
                {{ticket.description}}
            </p>
        </div>
    </div>


    <div class="card card-style" v-if="ticket">
        <div class="content">
            <div v-if="ticket.assigned_to_name">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-0 text-capitalize"
                    style="opacity: 1;background-color: transparent;">
                    Assign to
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-1 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ ticket.assigned_to_name }}
                </div>
            </div>

            <div v-for="value in ticket.attributeValues">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-0 text-capitalize"
                    style="opacity: 1;background-color: transparent;">
                    {{ value.attribute.name }}
                </label>
                <a :href="value.value" target="_blank"
                    class="letterSpacing py-2 color-blue-dark form-control fs-6 font-500 mb-1"
                    style="border:none; border-radius: 30px !important;background-color: rgb(213, 229, 254);"
                    v-if="value.attribute.type == 'input_file'">
                    <i class="fas fa-download letterSpacing"></i>&nbsp;Download
                </a>
                <div class="letterSpacing py-2 form-control fs-6 font-600 mb-0  text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(214, 255, 231);"
                    v-if="value.attribute.type != 'input_file'">
                    <span class="font-15 letterSpacing font-500" :class="[value.value > 0 ? 'text-success' : '']">
                        <span
                            v-if="ticket.ticket_type_id == getTicketTypeId('payment_request') && value.attribute.name == 'amount'">
                            {{ ticket.currency_symbol }}
                        </span>
                        {{value.value}}
                    </span>
                </div>
            </div>

            <div v-if="ticket.created_at">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-0 text-capitalize"
                    style="opacity: 1;background-color: transparent;">
                    Ticket Date
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-1 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ localDateTime(ticket.created_at)}}
                </div>
            </div>
        </div>
    </div>


    <!-- <div class="page-title-clear"></div> -->
    <h1 class="text-center letterSpacing primary-light-text font-500 font-19 mb-0 mt-5">
        Comments
    </h1>
    <div class="divider-icon divider-margins bg-gray-dark mb-5 mt-4">
        <i class="fa fa-comments mt-n3 font-30 primary-alt-light-text"></i>
    </div>



    <div class="page-content">
        <div class="content mt-0 mb-n5">
            <div v-for="c in comments">

                <!-- DATE & TIME -->
                <div class="font-11  w-100 d-block text-center">
                    <div class="text-secondary font-11 letterSpacing mt-3">{{localDateTime(c.created_at)}}</div>
                </div>

                <!-- INFORMATION CENTER -->
                <div class="speech-bubble speech-center text-center centerComment color-theme" v-if="c.user_id == null">
                    <div class="font-600 font-13 letterSpacing">{{c.comment}}
                        <!-- <em class="text-secondary font-11 float-end">{{localDateTime(c.created_at)}}</em> -->
                    </div>
                </div>


                <!-- SUPER ADMIN LEFT SIDE -->
                <p class="mb-1 letterSpacing text-dark font-500 fst-italic" v-if="(ticket.user_id != c.user_id)">
                    {{c.user_name}}
                </p>
                <div class="speech-bubble speech-right leftComment color-theme"
                    v-if="ticket.user_id != c.user_id && c.user_id != null">
                    <div class="mb-2 letterSpacing font-13 font-500 text-secondary">{{c.comment}}</div>
                    <div class="float-start mb-0" v-if="(c.user_name=='Super Admin') && c.attachment_path">
                        <a :href="c.attachment_path" target="_blank" class="chip chip-s viewFileBg text-white ms-0">
                            <i class="fas fa-eye color-white viewFileBg"></i>
                            <span class="color-white ms-n1">View File</span>
                        </a>
                    </div>
                    <!-- <div class="font-11">
                        <em class="text-secondary font-11 float-end">{{localDateTime(c.created_at)}}</em>
                    </div> -->
                </div>

                <!-- USER RIGHT SIDE -->
                <p class="mb-1 letterSpacing text-dark font-500 fst-italic text-end"
                    v-if="(ticket.user_id == c.user_id)">
                    {{ loginUserId == c.user_id ? 'You' : c.user_name}}
                </p>
                <div class="speech-bubble speech-left rightComment color-theme" v-if="ticket.user_id == c.user_id">
                    <div class="mb-2 letterSpacing font-13 font-500 text-secondary">{{c.comment}}</div>
                    <div class="float-start mb-0" v-if="(ticket.user_name == c.user_name) && c.attachment_path">
                        <a :href="c.attachment_path" target="_blank" class="chip chip-s viewFileBg text-white ms-0">
                            <i class="fas fa-eye color-white viewFileBg"></i>
                            <span class="color-white ms-n1">View File</span>
                        </a>
                    </div>
                    <!-- <div class="font-11">
                        <em class="text-secondary font-11 float-end">{{localDateTime(c.created_at)}}</em>
                    </div> -->
                </div>

                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>

<!-- footer -->
<app-footer v-if="hasPermission('ticket_comment_create')">
    <div id="footer-bar" class="d-flex pe-2"
        style="background-color: rgb(238,242,241);box-shadow: 0px -3px 10px rgb(172, 172, 172);">
        <div class="me-1 speach-icon">
            <a href="#" data-menu="menu-upload" class="bg-red-dark ms-2">
                <i class="fa fa-plus pt-2"></i>
            </a>
        </div>
        <div class="flex-fill speach-input">
            <Form novalidate @submit="submitComment" id="form">
                <input type="text" name="comment" v-model="newComment.comment" class="form-control shadow-s"
                    id="comment"
                    :placeholder="ticket.status == 'closed' ? 'comment here to reopen ticket anytime' : 'comment'">
            </Form>
        </div>
        <div class="ms-3 speach-icon" v-if="newComment.comment">
            <a href="javascript:void(0)" class="gradient-highlight color-white me-2" form="form"
                @click="submitComment"><i class="fa fa-arrow-up pt-2"></i></a>
        </div>
    </div>
</app-footer>

<!-- menu  -->
<div id="menu-upload" class="menu menu-box-bottom rounded-m" data-menu-height="275" data-menu-effect="menu-over">
    <div class="list-group list-custom-small ps-2 me-4 file-wrapper close-menu">
        <Form>
            <input type="file" class="comment-file" accept="*" @change="uploadFile">
        </Form>
        <a href="javascript:void(0)" class="file-input">
            <i class="font-14 fa fa-file color-gray2-dark"></i>
            <span class="font-13">File</span>
            <i class="fa fa-angle-right"></i>
        </a>
    </div>
</div>

<!-- manual payment action sheet -->
<div id="manualPaymentModal" class="menu menu-box-bottom rounded-m" data-menu-width="cover"
    data-menu-effect="menu-over">
    <div class="menu-title">
        <h1 class="font-24">Transaction Details</h1>
        <a href="#" class="close-menu"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="content mb-0 mt-0">
        <Form novalidate :validation-schema="formValidations" @submit="submitManualPaymentForm" v-slot="{ errors }"
            autocomplete="off">

            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.transactionId}">
                <i class="fas fa-id-card-alt"></i>
                <Field as="input" type="text" name="transactionId" v-model="transaction.transaction_id"
                    class="form-control" id="transactionId" placeholder="Transaction Id"></Field>
                <label for="transactionId" class="color-primary-dark">Transaction Id</label>
                <em>(required)</em>
                <template v-slot:error>
                    {{errors.transactionId}}
                </template>
            </form-control>

            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.created_at}">
                <i class="fas fa-calendar-alt"></i>
                <Field as="input" type="date" name="created_at" v-model="transaction.date" class="form-control"
                    id="date"></Field>
                <label for="createdAt" class="color-primary-dark">Transaction Date</label>
                <template v-slot:error>
                    {{errors.created_at}}
                </template>
            </form-control>

            <form-control class="validate-field input-group" :class="{'is-invalid' : errors.attachment}">
                <Field as="input" type="file" name="attachment" v-model="transaction.attachment" class="form-control"
                    id="attachment" aria-label="Upload" placeholder="Attachment"></Field>
                <label for="attachment" class="color-primary-dark">Transaction Attchment</label>
                <em>(required)</em>
                <template v-slot:error>
                    {{errors.attachment}}
                </template>
            </form-control>
            <button type="submit"
                class="btn btn-full btn-m shadow-l rounded-s font-600 bg-blue-dark w-100">Submit</button>
        </Form>
    </div>
</div>

<!-- auto payment action sheet -->
<div id="PayNowPaymentModal" class="menu menu-box-bottom rounded-m" data-menu-height="230" data-menu-effect="menu-over">
    <div class="menu-title">
        <i class="fa fa-question-circle scale-box float-start me-3 ms-3 fa-3x mt-1 color-blue-dark"></i>
        <p class="color-highlight">We need to know,</p>
        <h1 class="font-20">Are you sure Pay now?</h1>
        <a href="#" class="close-menu"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="content mt-0">
        <p class="pe-3">
            Once Approved, Payment Will be started to transfer.
        </p>
        <div class="row mb-0 justify-content-center">
            <div class="col-6">
                <a href="javascript:void(0)" class="btn close-menu btn-full btn-m bg-green-dark font-600 rounded-s"
                    @click="submitPayNowForm">Yes, Approve!</a>
            </div>
        </div>
    </div>
</div>
<app-footer />