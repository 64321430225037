import partnerData from "../../services"
import store from "../../../../store";

export default {
    data() {
        return {
            searchedResultShow: false,
            partners: {
                active: [],
                inactive: [],
                pending: [],
                invited: []
            },
            country_id: (this.$route.query.country ? this.$route.query.country : this.defaultFilterCountryId()),
            hasSuperAdmin: false,
            statistics: {},
            filter: {
                status: 'active',
                search: "",
                page: 1
            },
            meta: {
                current_page: 1,
            }
        }
    },
    watch: {
        'country_id': function () {
            this.filter.statistics = true;
            this.partners = {
                active: [],
                inactive: [],
                pending: [],
                invited: []
            };
            this.$router.replace({ query: { country: this.country_id } });
            this.filter.page = 1;
            this.getPartners();
        },
    },
    mounted: function mounted() {
        if ('user_type' in store.state.user && store.state.user.user_type == "super_admin") {
            this.hasSuperAdmin = true;
        }
        this.getPartners();
        this.getNextRecord();
    },
    methods: {
        tabchange(tab) {
            if (tab == "pending") {
                this.filter.status = "pending"
            } else if (tab == "inactive") {
                this.filter.status = "inactive"
            } else if (tab == "invited") {
                this.filter.status = "invited"
            } else {
                this.filter.status = "active"
            }
            if (Object.keys(this.partners[ this.filter.status ]).length == 0 && this.statistics[ this.filter.status ] > 0) {
                this.partners = {
                    active: [],
                    inactive: [],
                    pending: [],
                    invited: []
                };
                this.meta.current_page = this.filter.page = 1;
                this.getPartners();
            }
        },
        filterUpdate() {
            this.filter.page = 1;
            this.filter.statistics = true;
            this.getPartners();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.filter.page = 1;
            this.filter.statistics = true;
            this.filter.search = "";
            this.getPartners();
            this.searchedResultShow = false;
        },
        getPartners() {
            this.showLoader(true);
            this.filter.country_id = this.country_id;
            this.loading = true;
            if (this.filter.page == 1) {
                this.partners[ this.filter.status ] = [];
                this.filter.statistics = true;
            }
            if (this.filter.status != 'invited') {
                partnerData.getPartners(this.filter).then(response => {
                    this.hideLoader();
                    // console.log(this.partners[this.filter.status], response.data.data);
                    this.partners[ this.filter.status ].push(...response.data.data);
                    if (response.data.statistics) {
                        this.statistics = response.data.statistics
                        delete this.filter.statistics
                    }
                    this.loading = false;
                    this.meta = response.data.meta;
                });
            } else {
                partnerData.getInvitedPartners(this.filter).then(response => {
                    this.hideLoader();
                    // console.log(this.partners[this.filter.status], response.data.data);
                    this.partners[ this.filter.status ].push(...response.data.data);
                    if (response.data.statistics) {
                        this.statistics = response.data.statistics
                        delete this.filter.statistics
                    }
                    this.loading = false;
                    this.meta = response.data.meta;
                });
            }
        },
        getNextRecord() {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.meta.current_page != this.meta.last_page && !this.loading) {
                    this.filter.page = this.meta.current_page + 1;
                    this.getPartners();
                }
            }
        }
    }
}